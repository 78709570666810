:root{
  --main-color: #0b0b0b
}

a{
  color: inherit;
  text-decoration: none;
}

@mixin tablet{
  @media (max-width: 768px){
    @content;
  }
}

@mixin mobile{
  @media (max-width: 480px){
    @content;
  }
}