@import "../../app.scss";

.featured{
  height: 90vh;
  position: relative;

  .category{
    position: absolute;
    top: 80px;
    left: 50px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;

    select{
      cursor: pointer;
      background-color: var(--main-color);
      border: 1px solid white;
      color: white;
      margin-left: 20px;
      padding: 5px;
    }
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info{
    width: 35%;
    position: absolute;
    right: 50px;
    bottom: 100px;
    color: white;
    display: flex;
    flex-direction: column;

      img{
        width: 400px;

        @include mobile{
          width: 60vw;
        }
      }

      .desc{
        width: 450px;
        margin: 20px 0;

        @include mobile{
          width: 60vw;
        }
      }

      .buttons{
        display: flex;

        a{
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          margin-right: 10px;
          cursor: pointer;

          &.play{
            background-color: white;
            color: var(--main-color)
          }

          &.more{
            background-color: gray;
            color: white;
          }

          span{
            margin-left: 5px;
          }
        }
      }
  }
}