.list{
  width: 100%;
  margin-top: 10px;

  .listTitle{
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-left: 50px;
  }

  .wrapper{
    position: relative;

    .sliderArrow{
      width: 50px;
      height: 100%;
      background-color: rgb(22,22,22, 0.5);
      color: white;
      position: absolute;
      z-index: 99;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;

      &.left{
        left: 0;
      }

      &.right{
        right: 0;
      }
    }
    .container{
      margin-left: 50px;
      margin-top: 10px;
      display: flex;
      width: max-content;
      transform: translateX(0px);
      transition: all 1s ease;
    }
  }
}